import React, { useEffect, useState, useRef } from "react";
import { Routes, Route, Outlet, Link as RtLink } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";

import Privacy from "./Privacy";
import Provenance from "./Provenance";
import Terms from "./Terms";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link as MatLink } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
  return (
    <div>
      <Helmet>
        <title>BHA Home</title>
        <meta name="description" content="BobbleHeadArmy is a collection of programmatically generated artwork 
          coming from hand curated traits making each image unique living on the Ethereum blockchain. 
          Own a BobbleHead to be part of member-only products and utilities we are going to launch in near future." />
        <meta name="key" content="top nfts, top 10 nfts, trending nfts, mint top nfts, mint top 10 nfts, mint trending nfts"></meta>
      </Helmet>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          {/* <Route path="privacy" element={<Privacy />} />
          <Route path="provenance" element={<Provenance />} />
          <Route path="terms" element={<Terms />} /> */}
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <MatLink color="inherit" href="#">
          NeuraVerseNFT LLC
        </MatLink>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  const headerMenus = [
    { id: 1, name: 'mint', label: 'Mint' },
    { id: 2, name: 'about', label: 'About' },
    { id: 3, name: 'roadmap', label: 'Roadmap' },
    { id: 4, name: 'team', label: 'Team' },
    { id: 5, name: 'provenance', label: 'Provenance' }
  ];

  const socialMedias = [
    { id: 1, iconUrl: '/config/images/twitter.png', url: 'https://twitter.com/bobbleheadarmy' },
    { id: 2, iconUrl: '/config/images/discord.png', url: 'https://discord.gg/wryjTD9J8C' },
    { id: 3, iconUrl: '/config/images/instagram.png', url: 'https://www.instagram.com/bobbleheadarmynft/' },
    // { id: 4, iconUrl: '/config/images/reddit.png', url: 'https://www.reddit.com/' },
    { id: 4, iconUrl: '/config/images/linktree.png', url: 'https://linktr.ee/neuraversenft' }
  ];

  return (
    <div>
      <React.Fragment>
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Box
            sx={{
              p: 0
            }}>
            {/* HEADER */}
            <AppBar
              position="static"
              color="default"
              elevation={0}
              sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, backgroundColor: "var(--primary)" }}
            >
              <Toolbar sx={{ display: 'flex', flexDirection: { xs: "column", sm: "row" }, flexWrap: 'wrap' }}>
                <Grid sx={{ display: 'flex', flexDirection: "column" }}>
                  <IconButton sx={{ flexGrow: 0 }} component="span">
                    <RtLink to="/home"><img src={"/config/images/bha_logo.png"} style={{ height: 75, width: 75 }}></img></RtLink>
                    <span style={{ margin: "0px 5px", fontSize: "1.4em", color: "var(--primary-text)" }}>BobbleHeadArmy</span>
                  </IconButton>
                  <span style={{ color: "var(--primary-text)", fontSize: "0.8rem" }}>A NeuraVerseNFT Collection</span>
                </Grid>
                <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
                </Typography>
                <Grid>
                  {socialMedias.map((socialMedia) => (
                    <IconButton key={socialMedia.id} color="primary" component="a" target="_blank" href={socialMedia.url}>
                      <img src={socialMedia.iconUrl} style={{ height: 30, width: 30 }}></img>
                    </IconButton>
                  ))}
                </Grid>
              </Toolbar>
              <Toolbar sx={{ display: 'flex', flexDirection: { xs: "column", sm: "row" }, flexWrap: 'wrap' }}>
                {/* <Typography variant="span" color="var(--primary-text)" sx={{ flexGrow: 1 }}>
                  A NeuraVerseNFT Collection
                </Typography> */}
                <Typography variant="h6" color="inherit" sx={{ flexGrow: 3 }}>
                </Typography>
                <Grid sx={{ flexGrow: 5 }}>
                  <nav style={{ display: "flex", flexFlow: "wrap", justifyContent: { xs: "space-between" } }}>
                    {headerMenus.map((menu) => (
                      <MatLink key={menu.id} variant="button" href={'#' + menu.name}
                        sx={{ my: 1, mx: 1.5, color: "var(--primary-text)", fontSize: "1.6rem", textTransform: "none", textDecoration: "none", margin: "0px 25px" }}
                      >
                        {menu.label}
                      </MatLink>
                    ))}
                  </nav>
                </Grid>
                <Button href="#" variant="contained" size="large" sx={{ my: 1, mx: 1.5 }}>
                  Connect
                </Button>
              </Toolbar>

            </AppBar>
          </Box>
          <Box
            sx={{
              flex: '1 1 auto',
              p: 0,
              position: "relative"
            }}>
            <Outlet />
          </Box>
          <Box
            sx={{
              p: 0
            }}>
            {/* FOOTER */}
            <Grid sx={{ display: "flex", backgroundColor: "var(--primary)" }}>
              <IconButton sx={{ flexGrow: 0 }} component="span">
                <img src={"/config/images/bha_logo.png"} style={{ height: 50, width: 50 }}></img>
              </IconButton>
              <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
              </Typography>
              <Copyright sx={{ mt: 5, color: "var(--primary-text)" }} />
            </Grid>
          </Box>
        </Box>
      </React.Fragment>
    </div>
  );
}

function Home() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    ETHERSCAN_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mintTokens(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const employees = [
    {
      empId: 1,
      empName: 'Rio',
      designation: 'Development Bobblehead',
      imageUrl: '/config/images/bha_employee.png',
      altText: 'Bobble loading',
      description: 'Development Bobblehead'
    },
    {
      empId: 2,
      empName: 'Denver',
      designation: 'Marketing Bobblehead',
      imageUrl: '/config/images/bha_employee.png',
      altText: 'Head loading',
      description: 'Marketing Bobblehead'
    },
    {
      empId: 3,
      empName: 'Martian',
      designation: 'Bobblehead Artist',
      imageUrl: '/config/images/bha_employee.png',
      altText: 'Army loading',
      description: 'Bobblehead Artist'
    }
  ];

  const roadMaps = [
    { id: 1, step: "20%", description: "We are excited to share with you about what’s in store for us – follow us on Twitter & Discord for updates - to be announced soon!!" },
    { id: 2, step: "40%", description: "We are excited to share with you about what’s in store for us – follow us on Twitter & Discord for updates - to be announced soon!!" },
    { id: 3, step: "60%", description: "We are excited to share with you about what’s in store for us – follow us on Twitter & Discord for updates - to be announced soon!!" },
    { id: 4, step: "80%", description: "We are excited to share with you about what’s in store for us – follow us on Twitter & Discord for updates - to be announced soon!!" },
    { id: 5, step: "100%", description: "We are excited to share with you about what’s in store for us – follow us on Twitter & Discord for updates - to be announced soon!!" }
  ];

  return (
    <div>
      {/* MINT */}
      {/* <Container id={'mint'}>
        <Grid>
          <Typography>
            {data.totalSupply} / {CONFIG.MAX_SUPPLY}
          </Typography>
          <Typography>
            <Link target={"_blank"} href={CONFIG.ETHERSCAN_LINK + '/address/' + CONFIG.CONTRACT_ADDRESS }>
              View on Etherscan
            </Link>
          </Typography>
        </Grid>
        <Grid>
          {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
            <>
              <Typography>
                The sale has ended.
              </Typography>
              <Typography>
                You can still find {CONFIG.NFT_NAME} on
              </Typography>
              <Link target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                {CONFIG.MARKETPLACE}
              </Link>
            </>
          ) : (
            <>
              <Typography>
                1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                {CONFIG.NETWORK.SYMBOL}.
              </Typography>
              <Typography>
                Excluding gas fees.
              </Typography>
              {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                <Container ai={"center"} jc={"center"}>
                  <Typography>
                    Connect to the {CONFIG.NETWORK.NAME} network
                  </Typography>
                  <Button variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      getData();
                    }}
                  >
                    CONNECT
                  </Button>
                  {blockchain.errorMsg !== "" ? (
                    <>
                      <Typography>
                        {blockchain.errorMsg}
                      </Typography>
                    </>
                  ) : null}
                </Container>
              ) : (
                <>
                  <Typography>
                    {feedback}
                  </Typography>
                  <Container sx={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        decrementMintAmount();
                      }}
                    >
                      -
                    </Button>
                    <Typography>
                      {mintAmount}
                    </Typography>
                    <Button
                      variant="contained"
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        incrementMintAmount();
                      }}
                    >
                      +
                    </Button>
                  </Container>
                  <Container>
                    <Button
                      variant="contained"
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs();
                        getData();
                      }}
                    >
                      {claimingNft ? "BUSY" : "BUY"}
                    </Button>
                  </Container>
                </>
              )}
            </>
          )}
        </Grid>
      </Container> */}
      {/* ABOUT */}
      <Grid id={'about'} sx={{ display: "flex", flexDirection: { xs: "column-reverse", md: "row" }, bgcolor: "var(--secondary)", backgroundImage: `url("/config/images/bha_bg_mint.jpg")`, backgroundSize: "100% 100%" }}>
        <Box sx={{ flex: 1 }}></Box>
        <Box sx={{ flex: 4 }}>
          <Typography variant="h6" color="var(--secondary-text)" component="p" sx={{ px: 5, py: 3 }}>
            We are NeuraVerseNFT launching our first product as an NFT collection -  “BobbleHeadArmy”!!
          </Typography>
          <Typography variant="h6" color="var(--secondary-text)" component="p" sx={{ px: 5, py: 3 }}>
            BobbleHeadArmy is a collection of {CONFIG.MAX_SUPPLY} programmatically generated artwork
            coming from 3 hand curated traits making each image unique living on the
            Ethereum blockchain. Own a BobbleHead to be part of member-only products {'&'} utilities we are going to launch in near future.
          </Typography>
          <Typography variant="h6" color="var(--secondary-text)" component="p" sx={{ px: 5, py: 3 }}>
            BobbleHeadArmy is releasing soon, stay tuned to our discord channel to get updates.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
            m: 3,
            flex: 1
          }}
        >
          <img src="/config/images/bha_main.gif" alt="Image Loading..." style={{ maxHeight: "300px", minHeight: "100px", height: "300px", border: "2px solid var(--primary-border)" }}></img>
        </Box>
        <Box sx={{ flex: 1 }}></Box>
      </Grid>
      {/* ROADMAP */}
      <Grid id={'roadmap'} sx={{ display: "flex", backgroundColor: "Var(--primary)", backgroundImage: `url("/config/images/bha_bg_dark.jpg")`, backgroundSize: "100% 100%" }}>
        <Typography sx={{ flexGrow: 2 }}></Typography>
        <Container sx={{ flexGrow: 1 }}>
          <Grid sx={{ display: "flex", flexDirection: "column", p: 5, alignItems: "center" }}>
            <Button
              variant="contained"
              disabled
              sx={{ backgroundColor: "var(--primary-border)" }}
            >
              <Typography color="var(--primary-text)" align="center">
                Roadmap
              </Typography>
            </Button>
            <Typography color="var(--primary-text)">
              Hello 👋, to all the BobbleHead community out there.
              This is the first of its kind NFT where pixelated BobbleHeadArmy live on blockchain pinned on IPFS,
              our BobbleHeadArmy can do all sorts of things - wink, wave, drink, smoke, play and a lot more.
              Support the community by owning your favorite BobbleHead {'&'} join forces with the Army to be part of the WEB3
              world we are going to create {'&'} conquer.
            </Typography>
            <Typography sx={{ color: "var(--primary-text)", paddingTop: "20px" }} >
              We do not bobble yet - but, check our roadmap about the timeline when we add the attribute to all the
              BobbleHeadArmy.
            </Typography>
          </Grid>
          {roadMaps.map((roadMap) => (
            <Grid key={roadMap.id} sx={{ display: "flex", flexDirection: "column", p: "0px 40px", alignItems: "center" }}>
              <Button
                variant="outlined"
                disabled
                sx={{ minWidth: "80px", height: "80px", borderRadius: "50px", backgroundColor: "var(--primary-btn)" }}
              >
                <ul style={{ color: "var(--primary-btn-text)" }}>
                  <Typography component="li" variant="subtitle1" align="center">
                    {roadMap.step}
                  </Typography>
                </ul>
              </Button>
              <Typography color="var(--primary-text)">{roadMap.description}</Typography>
              {/* <div className="arrow" style={{ margin: "40px 0px 0px 20px"}}>
                <span></span>
                <span></span>
                <span></span>
              </div> */}
              {/* <IconButton sx={{ src: "config/images/downarrow.png", height: 30, width: 30 }}></IconButton> */}
              {roadMap.id !== roadMaps.length ? (
                <>
                  <IconButton disabled color="primary" component="span">
                    <img src="/config/images/downarrow.png" style={{ height: 100, width: 50 }}></img>
                  </IconButton>
                </>
              ) : null}
            </Grid>
          ))}
        </Container>
        <Typography sx={{ flexGrow: 2 }}></Typography>
      </Grid>
      {/* TEAM */}
      <Container id={'team'} sx={{ display: "flex" }}>
        <Grid container spacing={5} alignItems="flex-end" sx={{ m: "0px 0px 0px -40px" }}>
          {employees.map((employee) => (
            <Grid
              item
              key={employee.empId}
              xs={12}
              sm={6}
              md={4}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                  mb: 2,
                }}
              >
                <img src={employee.imageUrl} alt={employee.altText} style={{ width: "100%", border: "2px solid var(--primary-border)" }}></img>
              </Box>
              <Button
                fullWidth
                variant="outlined"
                disabled
                sx={{ backgroundColor: "var(--primary-border)" }}
              >
                <ul style={{ color: "var(--primary-text)" }}>
                  <Typography component="li" variant="subtitle1" align="center">
                    {employee.empName}
                  </Typography>
                  <Typography component="li" variant="subtitle1" align="center">
                    {employee.designation}
                  </Typography>
                </ul>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* CONTRACT */}
      <Grid sx={{ p: 2, display: "flex" }}>
        <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
        </Typography>
        <Paper sx={{ p: 2, margin: 'auto', maxWidth: 500, flexGrow: 1, backgroundColor: "var(--primary)", color: "var(--primary-text)" }}>
          <Grid item xs>
            <Typography gutterBottom variant="subtitle1" component="div">
              {/* Verified Contract: <MatLink target={"_blank"} href={CONFIG.ETHERSCAN_LINK + '/address/' + CONFIG.CONTRACT_ADDRESS }>View on Etherscan</MatLink>  */}
              Verified Contract: <MatLink>View on Etherscan</MatLink>
            </Typography>
            <Typography variant="body2" gutterBottom>
              Contact: <MatLink>neuraversenft@gmail.com</MatLink>
            </Typography>
            <Typography variant="body2">
              {/* <RtLink to="/privacy">Privacy Policy</RtLink> | <RtLink to="/terms">Terms</RtLink> */}
              <MatLink target={"_blank"} href="/PrivacyPolicy.pdf">Privacy Policy</MatLink> | <MatLink target={"_blank"} href="/TermsAndConditions.pdf">Terms</MatLink>
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
}

// function Privacy() {
//   return (
//     <div>
//       <h2>Privacy</h2>
//     </div>
//   );
// }

// function Terms() {
//   return (
//     <div>
//       <h2>Terms</h2>
//     </div>
//   );
// }

// function Provenance() {
//   return (
//     <div>
//       <h2>Provenance</h2>
//     </div>
//   );
// }

export default App;

